/**
 * WordPress dependencies
 */
import { SVG, Path } from '@wordpress/primitives';

const check = (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<Path d="M16.7 7.1l-6.3 8.5-3.3-2.5-.9 1.2 4.5 3.4L17.9 8z" />
	</SVG>
);

export default check;
